<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          v-if="canEdit"
          rounded
          dark
          class="common-botton"
          @click="$emit('showModal')"
        >
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
          v-if="rol == 'MANAGERCC' || rol == 'ADMINCC' || rol == 'ADMIN'"
          rounded
          dark
          class="common-botton ml-5"
          @click="dialog = true"
        >
          <span>
            Enviar telefono lista negra
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Telefono
        </span>
        <br />
        <span>
          {{ personalData.telefono ? personalData.telefono : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">
          Correo
        </span>
        <br />
        <span>
          {{ personalData.correo ? personalData.correo : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
    <CommonActionModal
      v-if="dialog"
      :dialog="dialog"
      :title="
        'Estás seguro de enviar el teléfono: ' +
          personalData.telefono +
          ' a la lista negra.'
      "
      :subtitle="'No podremos atender más leads con este número'"
      :bottonText="'Si envialo'"
      @closeModal="dialog = false"
      @userChoice="goToBlackList"
    />
  </div>
</template>

<script>
import moment from "moment";
import { updateLeadToBlackList } from "@/services/leads/leads.service";

export default {
  components: {
    CommonActionModal: () =>
      import("@/components/commonComponents/CommonActionModal.vue"),
  },
  props: {
    personalData: {
      type: Object,
      default: () => {},
    },
    stateList: Array,
    routesConfig: Object,
    listings: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },

  methods: {
    changeComponent() {
      this.$emit("changeComponent", 2);
    },
    getGenero(value) {
      return value == "M" ? "Masculino" : "Femenino";
    },
    getStateName(id) {
      return (
        this.stateList.find((e) => e.cEstado == id).dEstado ?? "No disponible"
      );
    },
    calculateEdad(value) {
      return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    getRol(value) {
      return this.listings.roles.find((e) => e.id == value)
        ? this.listings.roles.find((e) => e.id == value).nombre
        : "No disponible";
    },
    getStatus(value) {
      return (
        this.listings.status.find((e) => e.id == value).name ?? "No disponible"
      );
    },
    async goToBlackList(val) {
      if (val) {
        try {
          await updateLeadToBlackList(this.personalData.telefono);
          this.$toast.success("Accion realiza con exito");
        } catch {
          this.$toast.console.error("Sucedió algún error");
        }
      }
      this.dialog = false;
    },
  },
};
</script>
